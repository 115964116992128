import React, { useMemo } from 'react';
import styles from './Index.module.scss';
import UserInfo from '../UserInfo/Index';
import { useRouter } from 'next/router';
import { dashboardSitePrefixPath } from '@/utils/common';
import classNames from 'classnames';
import { userModelContainer } from '@/store/userModel';
import { headerContainer } from '../../store';

const LoginContent = () => {
  const router = useRouter();
  const { loginRedirect } = headerContainer.useContainer();
  return (
    <div className={styles.loginContent}>
      <span
        className={styles.registerOrDashBoardBtn}
        onClick={() => {
          router.push({
            pathname: `/user/login`,
            query: loginRedirect ? `redirect=${encodeURIComponent(location.pathname)}` : undefined,
          });
        }}
      >
        登录
      </span>
    </div>
  );
};

const EnterContent = () => {
  const router = useRouter();
  const hiddenDashboard = useMemo(() => {
    return router.route === '/market/chager/[service]';
  }, [router]);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ margin: '0 12px' }}>
        <UserInfo />
      </div>
      <span
        className={classNames(styles.registerOrDashBoardBtn, 'hover-register-btn')}
        onClick={() => {
          window.open(`${dashboardSitePrefixPath}/dashboard`);
        }}
        style={{ display: hiddenDashboard ? 'none' : undefined }}
      >
        账户
      </span>
    </div>
  );
};

const RightContent = () => {
  const { userInfo } = userModelContainer.useContainer();
  return userInfo.account ? <EnterContent /> : <LoginContent />;
};

export default RightContent;
