import type { IUserInfo } from '@/service/common/Index';
import { ISDKInfoResponse } from '@/service/market';
import type { IResponse } from '@/utils';
import { request, filterObject } from '@/utils';
import serverRequest from '@/utils/serverRequest';
import umiRequest from 'umi-request';
import type { ICartListData, ListData, TreeNode } from './types';

/**
 * 获取机器人类型
 * @returns
 */
export async function getRobotScene() {
  return request('/robot/scene');
}

export async function getDisplayType() {
  return request(`/robot/display_type`);
}

export type RobotType = 1 | 2 | 3;
export interface ITagResponse extends IResponse {
  data: TreeNode[];
}
/**
 * 查询机器人标签
 * @param type
 * @returns
 */
export async function getTagService(type: RobotType) {
  return request<ITagResponse>(`/home/tags/${type}`, {
    method: 'GET',
  });
}
export async function getTagUseServer(req: any, type: RobotType) {
  return serverRequest(req).get<ITagResponse>(`/home/tags/${type}`);
}

// export async function getPropertyService(type: RobotType) {
//   return request<IPropertyResponse>(`/home/properties/${type}`, {
//     method: 'GET',
//   });
// }
export interface IRobotTypeParams {
  properties?: number[];
  tag?: number[];
  keyword?: string;
  type?: number[];
}
export interface IMarketRobotListResponse extends IResponse {
  data: ListData[];
}
/**
 * 机器人列表
 * @param params
 * @returns
 */
export async function getMarketRobotList(params: IRobotTypeParams, robotype: 1 | 2 | 3) {
  if (robotype === 1) {
    return request<IMarketRobotListResponse>(`/home/robot/list`, {
      method: 'POST',
      data: filterObject(params),
    });
  }
  if (robotype === 2) {
    return request<IMarketRobotListResponse>(`/home/mobile_sdk/list`, {
      method: 'POST',
      data: filterObject(params),
    });
  }
}
export async function getMarketRobotListUseServer(
  req: any,
  params: IRobotTypeParams,
  robotType: RobotType,
) {
  const serverURL = robotType === 1 ? `/home/robot/list` : `/home/mobile_sdk/list`;
  return serverRequest(req, true).post<IMarketRobotListResponse>(serverURL, {
    data: filterObject(params),
  });
  // if (robotype === 2) {
  //   return request<IMarketRobotListResponse>(`/home/mobile_sdk/list`, {
  //     data: filterObject(params),
  //   });
  // }
}

/**
 * 热门推荐
 */
export async function getHotRobot(scene: number[], product_type: number) {
  return request<IMarketRobotListResponse>(`/robot/hot`, {
    method: 'POST',
    data: {
      scene,
      product_type,
    },
  });
}

/**
 * 获取用户信息
 */
export async function getUserInfo() {
  return request<IUserInfo>('/service/user/info', {
    method: 'GET',
  });
}

export interface IMobileSdkCartListResponse extends IResponse {
  data: {
    list: ICartListData[];
  };
}

/**
 * 移动端sdk购物车列表
 */
export async function getMobileSdkCartList() {
  return request<IMobileSdkCartListResponse>(`/home/mobile_sdk/carts/list`, {
    method: 'POST',
  });
}

/**
 * 移动端sdk购物车列表
 */
export async function addToCartService(sdk_id: string) {
  return request<IResponse>(`/home/mobile_sdk/carts/add`, {
    method: 'POST',
    data: {
      sdk_id,
    },
  });
}
export async function getSdkInfo(service: string) {
  return request<ISDKInfoResponse>(`/home/mobile_sdk/${service}/detail`, {
    method: 'GET',
  });
}
/**
 * 获取ip所属的国家，判断是否是中国大陆
 */
export async function isNotCNByIp(req) {
  const ipAddress =
    req.headers['x-forwarded-for'] || req.headers['x-real-ip'] || req.connection.remoteAddress;
  try {
    const res = await umiRequest.post(`https://arch-api.intsig.net/ipsearch?ip=${ipAddress}`);
    const { code, continent } = res?.data || {};
    return code ? code !== 'CN' && continent !== '保留IP' : false;
  } catch (error) {
    return false;
  }
}
