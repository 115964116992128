import type { IResponse } from '@/utils';
import { request } from '@/utils';
import type { IDocumentItem, IType, IDocumentDetail } from './data';

export interface IQueryListRes extends IResponse {
  data: {
    list: IDocumentItem[];
    count: number;
  };
}

export interface IQueryListReq {
  keyword?: string;
  page_size: number;
  page_num: number;
  classify?: 'api_doc' | 'help_doc';
}
export async function queryList(params: IQueryListReq) {
  return request<IQueryListRes>('/doc/search', {
    method: 'POST',
    data: params,
  });
}
export async function queryDetail(key: string, uuid) {
  return request<IQueryListRes>('/doc/info', {
    method: 'POST',
    data: {
      path: key,
      uuid: uuid,
    },
  });
}
interface IChIldrenItem {
  path: string;
  title: string;
}
interface IconfigQustion {
  path: string;
  title: string;
  children: IChIldrenItem[];
}
interface IResConfigQuestion extends IResponse {
  data: {
    faq: IconfigQustion[];
  };
}
interface IResgetCatalogQuestion extends IResponse {
  data: any[];
}
export async function getConfigQuestion() {
  return request.get<IResConfigQuestion>('/doc/home/config');
}
export async function getCatalogQuestion() {
  return request.get<IResgetCatalogQuestion>('/doc/catalog');
}
export interface IDocFileRes extends IResponse {
  data: {
    file_type: string;
    file_content: string;
  };
}
export async function getDocFile(key: string) {
  return request<IDocFileRes>(`/doc/file`, {
    method: 'POST',
    data: {
      path: key,
    },
  });
}

/**
 *
 * @param service 问题反馈
 */
interface Iparams {
  path: string;
  summary: string;
  error_text: string;
  description: string;
  uri: string;
}
interface Iresponse {
  code: number;
  msg: string;
  data: {
    received: number;
  };
}
export async function feedbackService(params: Iparams) {
  return request<Iresponse>('/doc/feedback', {
    method: 'POST',
    data: params,
  });
}
interface IThumbsparams {
  path: string;
  uuid?: string;
}
interface IThumbsResponse extends IResponse {
  data: {
    received: number;
  };
}
export async function postThumbsCancleUp(params: IThumbsparams) {
  return request<IThumbsResponse>('/doc/cancel_thumbs_up', {
    method: 'POST',
    data: params,
  });
}
export async function postThumbsUp(params: IThumbsparams) {
  return request<IThumbsResponse>('/doc/thumbs_up', {
    method: 'POST',
    data: params,
  });
}
export async function postThumbsDown(params: IThumbsparams) {
  return request<IThumbsResponse>('/doc/thumbs_down', {
    method: 'POST',
    data: params,
  });
}
interface IQueryRes extends IResponse {
  data: string[];
}
export async function query(key: string) {
  return request<IQueryRes>(`/help_document/document/search/completion`, {
    method: 'POST',
    data: {
      keyword: key,
    },
  });
}
interface IRobotsResponse extends IResponse {
  data: {
    name: string;
    service: string;
    banner: string;
    usage: number;
  };
}
export async function getAdRobots() {
  return request<IRobotsResponse>('/doc/ad_robots');
}
interface ITypeResponse extends IResponse {
  data: IType[];
}
export async function queryTypes() {
  return request<ITypeResponse>('/backend/help_document/types');
}
export interface IQuestionProp {
  id: number;
  title: string;
}
export interface ICommonQuestionRes extends IResponse {
  data: IQuestionProp[];
}

export async function getCommonQuestion() {
  return request.get<ICommonQuestionRes>('/help_document/document/question');
}
export interface IDocDetailRes extends IResponse {
  data: IDocumentDetail;
}

export async function getDocumentDetail(id: string) {
  return request<IDocDetailRes>(`/backend/help_document/document/${id}`);
}
