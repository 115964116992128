import type { FC } from 'react';
import { Children, cloneElement, isValidElement, useCallback } from 'react';
import CSSMotion from 'rc-motion';
import classNames from 'classnames';
interface CollapseProps {
  collapse: boolean;
  timeout?: number;
  afterLeave?: () => void;
}
const elTransition =
  'height .2s cubic-bezier(.215,.61,.355,1),opacity .2s cubic-bezier(.215,.61,.355,1)';
const Collapse: FC<CollapseProps> = ({ children, collapse, afterLeave }) => {
  const onEnterStart = useCallback((el) => {
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;
    el.dataset.oldOverflow = el.style.overflow;
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.opacity = 0;
    el.style.paddingBottom = 0;
    el.style.overflow = 'hidden';
  }, []);
  const onEnterActive = useCallback((el) => {
    if (el.scrollHeight !== 0) {
      el.style.transition = elTransition;
      el.style.height = el.scrollHeight + 'px';
      el.style.opacity = 1;
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    } else {
      el.style.height = '';
      el.style.opacity = '';
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    }
    el.style.overflow = 'hidden';
  }, []);
  const onEnterEnd = useCallback((el) => {
    el.style.transition = '';
    el.style.height = '';
    el.style.opacity = '';
    el.style.overflow = '';
  }, []);
  const onLeaveStart = useCallback((el) => {
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;
    el.dataset.oldOverflow = el.style.overflow;
    el.style.height = 0;
    el.style.opacity = 0;
    el.style.overflow = 'hidden';
    el.style.transition = elTransition;
  }, []);
  const onLeaveActive = useCallback((el) => {
    if (el.scrollHeight !== 0) {
      el.style.opacity = 0;
      el.style.height = 0;
      el.style.paddingTop = 0;
      el.style.paddingBottom = 0;
    }
  }, []);
  const onLeaveEnd = useCallback((el) => {
    el.style.transition = '';
    el.style.height = '';
    el.style.opacity = '';
    el.style.overflow = el.dataset.oldOverflow;
    el.style.paddingTop = el.dataset.oldPaddingTop;
    el.style.paddingBottom = el.dataset.oldPaddingBottom;
    afterLeave?.();
  }, []);
  const generateChildren = useCallback(
    ({ className }) => {
      Children.only(children);
      const clonedChildren = Children.map(children, (child) => {
        if (isValidElement(child)) {
          const { className: childClass } = child.props;
          return cloneElement(child, {
            className: classNames(childClass, className),
          } as any);
        }
      });
      return clonedChildren![0];
    },
    [children],
  );
  return (
    <CSSMotion
      visible={collapse}
      onEnterStart={onEnterStart}
      onEnterActive={onEnterActive}
      onEnterEnd={onEnterEnd}
      onLeaveStart={onLeaveStart}
      onLeaveActive={onLeaveActive}
      onLeaveEnd={onLeaveEnd}
      motionName="slide"
      removeOnLeave={false}
      motionAppear
    >
      {generateChildren}
    </CSSMotion>
  );
};
export default Collapse;
