import { useState } from 'react';
import { useEventListener } from 'ahooks';

const useWinTop = (maxTop) => {
  // const [target,setTarget]=useState<any>()
  // const scroll=useScroll(target);
  // useEffect(()=>{
  //     setTarget(document.documentElement)
  // },[])
  // console.log(scroll,'scroll')
  // return scroll.top
  const [top, setTop] = useState(0);
  useEventListener('scroll', () => {
    const top =
      (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    if (top < maxTop) {
      setTop(top);
    } else {
      setTop(maxTop + 1);
    }
  });
  return top;
};

export default useWinTop;
