import type { ImageProps } from 'next/image';
import NextImage from 'next/image';

function Image({ src, children, ...rest }: ImageProps) {
  return (
    <NextImage src={src} {...rest}>
      {children}
    </NextImage>
  );
}
export default Image;
