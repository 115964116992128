import classNames from 'classnames';
import type { LinkProps as NextLinkProps } from 'next/link';
import type { FC } from 'react';

export interface LinkProps extends Omit<NextLinkProps, 'href'> {
  isNative?: boolean;
  target?: '_self' | '_blank';
  onClick?: React.MouseEventHandler;
  className?: string;
  href?: string;
}

/**
 *
 * @param isNative
 *  - 如果 url 是 string是 渲染原始a标签（为了处理浏览器点击返回按钮到首页定位历史位置)
 *  - 如果url URlObject时用next Link渲染
 *
 */
const Link: FC<LinkProps> = ({
  isNative,
  href,
  children,
  target = '_blank',
  onClick = () => {},
  className,
  passHref,
  ...rest
}) => {
  return (
    <a
      href={href}
      {...rest}
      target={target}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick(e);
      }}
      className={classNames('custom-link', className)}
    >
      {children}
    </a>
  );
};
export default Link;
