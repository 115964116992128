import type { ReactNode } from 'react';
import ProductPopoverContent from './components/ProductPopover/Dropdown';
import DocumentPopover from './components/DocumentPopover/DocumentPopover';
import ExperiencePopover from './components/ExperiencePopover/ExperiencePopover';
import { HotIcon, NewIcon } from './components/Icon';
import { getToken, getToolsUrl } from '@/utils';

export interface IRoutePathItem {
  text: string;
  path: string;
  description?: string;
  active?: boolean;
  logKey?: string;
  target?: '_self' | '_blank';
  icon?: ReactNode;
}

export const menu = [
  {
    path: `/market/list`,
    target: '_blank',
    title: '产品',
    key: 'product',
    attrs: {
      ['data-title']: '申请和开通机器人',
    },
    showCord: true,
    newTag: false,
  },
  {
    path: '/experience/list',
    title: '体验中心',
    key: 'experience',
    target: '_blank',
    showMenu: true,
    showCord: true,
    // component: <ExperiencePopover />,
  },
  {
    path: `/news/list`,
    title: '资讯中心',
    key: 'news',
    target: '_blank',
    showCord: false,
    attrs: {
      ['data-title']: '资讯中心',
    },
  },
  {
    path: '/document/list',
    title: '文档与支持',
    key: 'document',
    showMenu: true,
    showCord: true,
    target: '_blank',
    // visible:true,
    // component: <DocumentPopover />,
  },
  {
    path: undefined,
    onClick: () => {
      const token = getToken();
      let url = `${getToolsUrl()}/`;
      if (token) {
        url = url + '?_textin_token=' + token;
      }
      window.open(url);
    },
    title: 'TextIn Tools',
    key: 'tools',
    target: '_blank',
    showCord: false,
    width: 85,
    attrs: {
      ['data-title']: 'TextIn Tools',
    },
  },
  {
    path: `/about`,
    title: '关于我们',
    key: 'about',
    target: '_blank',
    showCord: false,
    attrs: {
      ['data-title']: '了解合合与TextIn',
    },
  },
];
export const popupMenu = [
  {
    component: ProductPopoverContent,
    key: 'product',
  },
  {
    component: DocumentPopover,
    key: 'document',
  },
  {
    component: ExperiencePopover,
    key: 'experience',
  },
];

const AIModelList: IRoutePathItem[] = [
  {
    text: '通用文档解析',
    path: `/market/detail/pdf_to_markdown`,
    description: '加速大模型的训练、开发、应用',
    logKey: 'pdf_to_markdown',
    icon: <HotIcon />,
  },
  {
    text: '通用文本向量',
    path: `/market/detail/acge_text_embedding`,
    description: '中文榜单榜首的文本向量模型，速度快、体积小、搜得准',
    logKey: 'acge_text_embedding',
    icon: <NewIcon />,
  },
  {
    text: '智能文档抽取',
    path: `/product/textin_extract`,
    description: '智能理解文档内容，提取关键要素',
    logKey: 'extract',
    icon: <NewIcon />,
  },
];

const BaseProductList: IRoutePathItem[] = [
  {
    text: '通用文字识别类',
    path: '/product/textin_text',
    description: '文字识别、表格识别等基础识别场景',
    logKey: 'text',
  },
  {
    text: '票据文字识别/验真',
    path: '/product/textin_bill',
    description: '国内常见票据文字识别及增票验真',
    logKey: 'bill',
  },
  {
    text: '卡证文字识别类',
    path: '/product/textin_card',
    description: '身份证、名片、营业执照等国内外卡证文字识别',
    logKey: 'card',
  },
];

const ProcessList: IRoutePathItem[] = [
  {
    text: '图像处理类',
    path: '/product/textin_image',
    description: '图像水印去除、PS检测、自动擦除、去屏幕纹',
    logKey: 'image',
    icon: <HotIcon />,
  },
  {
    text: '文档格式转换类',
    path: `/product/textin_conversion`,
    description: 'PDF/Word/Excel等格式转换',
    logKey: 'studio',
  },
];

const sceneProductList: IRoutePathItem[] = [
  {
    text: '合同机器人',
    path: `/product/textin_contract`,
    description: '智能合同比对抽取工具',
    logKey: 'contract',
  },
  {
    text: '财经知识库问答',
    path: `/product/textin_intfinq`,
    description: '智能信息检索工具',
    logKey: 'knowledge_base',
  },
  {
    text: 'DocFlow 票据自动化',
    path: `/product/textin_docflow`,
    description: '票据AI自动化处理',
    logKey: 'docflow',
  },
];

const OtherList: IRoutePathItem[] = [
  {
    text: '文字识别训练云平台',
    path: `/product/textin_studio`,
    description: '无需OCR开发基础的全流程一站式开发平台',
    logKey: 'studio',
  },
  {
    text: 'AI实验室',
    // icon: '/images/navigation/ic-new@2x.png',
    path: `/laboratory`,
    description: '新科技首发体验',
    logKey: 'laboratory_ai',
  },
];

export { BaseProductList, sceneProductList, ProcessList, OtherList, AIModelList };
