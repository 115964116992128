import type { FC } from 'react';
import styles from './index.module.scss';
import Image from 'next/image';
interface ToolCardProps {
  url: string;
  title: string;
  onClick?: () => void;
}
const ToolCard: FC<ToolCardProps> = ({ title, url, ...otherProps }) => {
  return (
    <div className={styles.card} {...otherProps}>
      <Image src={url} alt="" width={40} height={40} />
      <div className={styles.title}>{title}</div>
    </div>
  );
};
export default ToolCard;
