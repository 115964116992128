import { request } from '@/utils/request';
export interface ItemParams {
  tags: number[];
  properties: number[];
  keyword: string;
}
export interface ItemRes {
  code: number;
  msg: string;
  data: Record<string, any>[];
}

export async function getConsultation(params: ItemParams) {
  return request<ItemRes>('/home/robot/list', {
    method: 'POST',
    data: params,
  });
}
export interface ItemTypeRes {
  code: number;
  msg: string;
  data: {
    properties: [];
  };
}

export async function getProperties() {
  return request<ItemTypeRes>('/home/properties/1');
}
