import { getChatUrl } from './env';
let windowObjectReference: Window | null = null;

export const openChat = () => {
  if (windowObjectReference == null || windowObjectReference.closed) {
    const url = getChatUrl();
    windowObjectReference = window.open(
      url,
      'propChatWindow',
      'width=800,height=618,resizable,scrollbars,status',
    );
  } else {
    windowObjectReference.focus();
  }
};
