import { useState } from 'react';
import { createContainer } from 'unstated-next';

export type ITheme = 'dark' | 'light';

interface IHeaderProps {
  theme: ITheme;
  popoverClass?: string;
  loginRedirect?: boolean;
}

function useStore(props?: IHeaderProps) {
  const { loginRedirect = true } = props || {};
  const [popoverClassName] = useState(props?.popoverClass);
  // 用于记录header主题模式, dark模式hover后会变light
  const [theme, setTheme] = useState<ITheme>(props?.theme || 'dark');

  const [popupVisible, setPopupVisible] = useState(false);

  return {
    popoverClassName,
    theme,
    changeTheme: setTheme,
    loginRedirect,
    popupVisible,
    setPopupVisible,
  };
}

export const headerContainer = createContainer(useStore);
