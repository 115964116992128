import { useEffect } from 'react';
import { useRouter } from 'next/router';

function useRouterMount(callback) {
  const { isReady } = useRouter();

  useEffect(() => {
    if (isReady) {
      callback();
    }
  }, [isReady]);
}

export default useRouterMount;
