import { Col, Divider, Row } from 'antd';
import React from 'react';
import {
  AIModelList,
  BaseProductList,
  OtherList,
  sceneProductList,
  ProcessList,
} from '../../constants';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useDocumentWidth } from '../../hooks';
import { headerContainer } from '@/components/Header/store';
import Image from 'next/image';
// import AdvertCard from '@/components/AdvertCard';

const RenderItem = ({ list }) =>
  list.map(({ text, description, path, logKey, icon }) => {
    return (
      <Col
        span={24}
        key={logKey}
        className={styles.popoverLinkBox}
        onClick={() => {
          window.open(path, '_blank');
        }}
      >
        <div className={styles.productItem}>
          <div className={styles.tagWrapperLine}>
            <span className={styles.popoverLinkText}>{text}</span>
            {icon && (
              <span style={{ display: 'flex', marginLeft: 8 }}>
                {typeof icon === 'string' ? (
                  <Image src={icon} alt="" width={32} height={18} />
                ) : (
                  icon
                )}
              </span>
            )}
          </div>
          <div className={styles.popoverDesc} title={description}>
            {description}
          </div>
        </div>
      </Col>
    );
  });

const ProductPopoverContent = () => {
  useDocumentWidth('.document-popover-effect');
  const { popoverClassName } = headerContainer.useContainer();
  return (
    <div className={classNames(styles.navBox, 'document-popover-effect', 'navBox')}>
      <div className={classNames(styles.navLeft, 'navLeft')}>
        <div className={classNames(styles.leftContent, 'leftContent')}>
          <div className="navLeftTitle">产品中心</div>
          <div className="navLeftDesc">
            提供高精准度的智能文字识别引擎及场景化产品，支持多种部署方式，帮助您提升文档处理流程的效率
          </div>
          <div>
            <a href="/market/list" target="_blank">
              <div className={styles.product}>
                <span>查看所有产品</span>
                <div className={styles.icon_arrow} />
              </div>
            </a>
            <a href="/contact" target="_blank">
              <div className={styles.product}>
                <span>提交需求</span>
                <div className={styles.icon_arrow} />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div
        className={classNames(styles.navContent, popoverClassName, 'product-popover', 'navContent')}
      >
        <Row style={{ width: '100%', flexWrap: 'nowrap' }}>
          <Col style={{ width: 264, marginRight: 60, background: '#fff', flexShrink: 0 }}>
            <div className={styles.popoverTitle}>大模型加速器</div>
            <Divider style={{ margin: '20px 0 10px' }} />
            <Row className={styles.productRow} style={{ marginBottom: 28 }}>
              <Col className={styles.productList1}>
                <RenderItem list={AIModelList} />
              </Col>
            </Row>

            <div className={styles.popoverTitle}>基础识别产品</div>
            <Divider style={{ margin: '20px 0 10px' }} />
            <Row className={styles.productRow}>
              <Col className={styles.productList1}>
                <RenderItem list={BaseProductList} />
              </Col>
            </Row>
          </Col>

          <Col style={{ width: 264, marginRight: 60, background: '#fff', flexShrink: 0 }}>
            <div className={styles.popoverTitle}>文档处理</div>
            <Divider style={{ margin: '20px 0 10px' }} />
            <Row className={styles.productRow} style={{ marginBottom: 28 }}>
              <Col className={styles.productList2}>
                <RenderItem list={ProcessList} />
              </Col>
            </Row>

            <div className={styles.popoverTitle}>企业智能应用</div>
            <Divider style={{ margin: '20px 0 10px' }} />
            <Row className={styles.productRow}>
              <Col className={styles.productList1}>
                <RenderItem list={sceneProductList} />
              </Col>
            </Row>
          </Col>

          <Col style={{ width: 264, marginRight: 60, background: '#fff', flexShrink: 0 }}>
            <div className={styles.popoverTitle}>更多应用</div>
            <Divider style={{ margin: '20px 0 10px' }} />
            <Row className={styles.productRow} style={{ marginBottom: 28 }}>
              <Col className={styles.productList1}>
                <RenderItem list={OtherList} />
              </Col>
            </Row>

            <div className={styles.popoverTitle}>更多服务</div>
            <Divider style={{ margin: ' 20px 0 10px' }} />

            <Row className={styles.productList4}>
              <div className={styles.productBox}>
                <div className={styles.productText}>
                  未找到适合的产品？可进行
                  <a className={styles.hrefBtn} href="/contact?type=18" target="_blank">
                    需求定制
                  </a>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>

      <div className={classNames(styles.navRight, 'navRight')}>{/* 广告区 */}</div>
    </div>
  );
};
export default ProductPopoverContent;
