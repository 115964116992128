/* eslint-disable @next/next/no-img-element */
import styles from './index.module.scss';
import { descriptions } from './constants';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Space, Row, Col, Divider, Tooltip } from 'antd';
import classNames from 'classnames';
import Link from '@/components/Link';
import Image from 'next/image';
import { ICPCode, securityCode } from './constants';
import { officialTel } from '@/utils/static';
interface FooterProps {
  theme?: 'light' | 'dark';
}
const Footer: FC<FooterProps> = ({ children, theme }) => {
  const generateDescList = useMemo(() => {
    return (
      <Space
        align={'start'}
        size={[80, 0]}
        style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'nowrap' }}
      >
        {descriptions.map((item) => (
          <div key={item.title}>
            <p className={styles.desc_title}>{item.title}</p>
            <div
              className={classNames(styles.desc_container, {
                [styles['multi-col']]: Array.isArray(item.children[0]),
              })}
            >
              {item.children.map((child, colIdx) => {
                return Array.isArray(child) ? (
                  <div key={colIdx} className={styles['multi-col-item']}>
                    {child.map((childItem) => (
                      <div
                        key={childItem.desc}
                        className={classNames(childItem.className, styles.link_item)}
                        style={{ marginBottom: 15 }}
                      >
                        <Link href={childItem.href} target={childItem.target} isNative>
                          <div>{childItem.desc}</div>
                        </Link>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={{ marginBottom: 15 }} className={styles.link_item} key={child.desc}>
                    <Link href={child.href} target={child.target} isNative>
                      <div> {child.desc}</div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </Space>
    );
  }, []);
  return (
    <>
      {children}
      <div className={classNames(styles.footer_wrapper, styles[`footer-${theme}`])}>
        <div className={styles.footer_content}>
          <div className={styles.footer_top}>
            <div className={styles.left}>
              {/* <FooterLogo /> */}
              <Link href="/" target="_self">
                <Image
                  src={
                    theme === 'dark'
                      ? '/images/home/logo-footer-white.png'
                      : '/images/footer_logo@2x.png'
                  }
                  width={174}
                  height={44}
                  alt=""
                />
              </Link>
              <div className={styles.leftFontBox} />
              <Link href="/" target="_self">
                <div className={styles.leftBtm}>
                  <p className={styles.leftTitle}>合合信息旗下品牌</p>
                  <p className={styles.leftContent}>专注智能文字识别16年</p>
                </div>
              </Link>
            </div>

            <div className={styles.right}>{generateDescList}</div>
          </div>
          <Row className={styles.foot_center}>
            <Col>
              友情链接：
              <Space size={15}>
                <a rel="" href="https://www.intsig.com/" target="_blank">
                  <span className={styles.linkText}>合合信息</span>
                </a>
                <a rel="" href="https://www.camscanner.com/" target="_blank">
                  <span className={styles.linkText}>扫描全能王</span>
                </a>
                <a rel="" href="https://www.camcard.com/" target="_blank">
                  <span className={styles.linkText}>名片全能王</span>
                </a>
                <a rel="" href="https://www.qixin.com/" target="_blank">
                  <span className={styles.linkText}>启信宝</span>
                </a>
              </Space>
            </Col>
            <Col style={{ textAlign: 'right', flex: 1 }}>
              <Row style={{ justifyContent: 'flex-end' }}>
                <Col className={styles.iconphone}>
                  <Image src={'/icons/phone_black.svg'} width={20} height={20} alt="" />
                </Col>
                <Col className={styles.phone}>{officialTel}</Col>
                <Col className={styles['working-time']}>周一至周日9:00-18:00 (法定假日除外)</Col>
              </Row>
            </Col>
          </Row>
          <Divider style={{ margin: '16px 0' }} />
          <div className={styles.footer_bottom}>
            <div className={styles['company-info']}>
              <Space size={0} direction="vertical">
                <Space size={8}>
                  <a
                    target="_blank"
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602005698"
                    rel=""
                  >
                    {securityCode}
                  </a>
                  <span>Textin.com 版权所有@{`${new Date().getFullYear()}`}</span>
                  <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="">
                    {ICPCode}
                  </a>
                </Space>
                <div>公司地址：上海市静安区万荣路1268号云立方A座11层</div>
                <Space size={8}>
                  <span>不良信息举报电话：{officialTel}</span>
                  <span>举报邮箱：support@textin.com</span>
                </Space>
              </Space>
            </div>

            <div className={styles['company-certificate']}>
              <Space size={8}>
                <Tooltip
                  trigger="click"
                  overlayClassName={styles.footerToolTip}
                  overlayInnerStyle={{
                    background: '#fff',
                  }}
                  placement="top"
                  title={
                    <img style={{ width: 624 }} alt="" src="/images/business_ license_pop.jpg" />
                  }
                >
                  <div
                    style={{
                      position: 'relative',
                      width: 137,
                      height: 58,
                      cursor: 'pointer',
                      marginBottom: '5px',
                    }}
                  >
                    <Image src="/images/business_license.png" alt="" layout="fill" />
                  </div>
                </Tooltip>

                <a
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602005698"
                  target="_blank"
                  rel=""
                >
                  <Image src="/images/network_supervisor.png" alt="" width="141" height="58" />
                </a>
                <a href="http://www.shjbzx.cn/" target="_blank" rel="">
                  <Image src="/images/report_center.png" alt="" width="182" height="58" />
                </a>
              </Space>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
