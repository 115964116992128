import type { ReactNode } from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSize } from 'ahooks';
export const useDocumentWidth = (className: string) => {
  const size = useSize(() => document.querySelector(className));
  useEffect(() => {
    const htmlElementWidth = document.documentElement.getBoundingClientRect().width;
    const targetDom = document.querySelector(className);
    if (targetDom) {
      (targetDom as HTMLDivElement).style.width = htmlElementWidth + 'px';
    }
  }, [size]);
};
export const useBodyAppend = (elements: ReactNode[] | ReactNode) => {
  function createElement(ele: ReactNode) {
    const wrapper = document.createElement('div');
    document.body.appendChild(wrapper);
    ReactDOM.render(ele, wrapper);
  }
  useEffect(() => {
    if (Array.isArray(elements)) {
      elements.forEach((ele) => {
        createElement(ele);
      });
    } else {
      createElement(elements);
    }
  }, []);
};
