import PinyinMatch from 'pinyin-match';

const pinyinSearch = (data: any[], keys: string[], keyword: string): any[] => {
  if (!Array.isArray(data)) return data;
  const result = data
    .map((op) => {
      let match: Record<string, any> | null = null;
      keys.map((key) => {
        const result = PinyinMatch.match(op[key], keyword);
        if (Array.isArray(result)) {
          if (!match) {
            match = {};
          }
          match[key] = op[key]
            .split('')
            .map((item, i) =>
              i >= result[0] && i <= result[1] ? (
                <span className="pingyin-active">{item}</span>
              ) : (
                item
              ),
            );
        }
      });
      return {
        ...op,
        match,
      };
    })
    .filter((op) => op.match);

  if (/^[a-zA-Z]+$/g.test(keyword)) {
    const word = keyword.toLowerCase();
    result.sort((pre: any, next: any) => {
      const preKey = keys.some((key) => pre[key]?.toLowerCase()?.includes(word));
      const nextKey = keys.some((key) => next[key]?.toLowerCase()?.includes(word));
      if (!preKey && nextKey) {
        return 1;
      }
      return -1;
    });
  }

  return result;
};

export default pinyinSearch;
