import type { FC } from 'react';
import ToolCard from '../ToolCrad';
import styles from './index.module.scss';
interface ToolsProps {
  title: string;
  cardList: { title: string; url: string; onClick?: () => void }[];
}
const Tools: FC<ToolsProps> = ({ title, cardList }) => {
  return (
    <div className={styles.tools}>
      <div className={styles.title}>{title}</div>
      <div className={styles.divider} />
      {cardList.map((cardItem, idx) => {
        return <ToolCard key={idx.toString()} {...cardItem} />;
      })}
    </div>
  );
};
export default Tools;
