import { useRouter } from 'next/router';
import classnames from 'classnames';
import type { CSSProperties } from 'react';
import React, { useState, useEffect, useRef } from 'react';
import { useDebounceFn } from 'ahooks';
import NaveBar from './component/Navigation';
import RightContent from './component/RightContent/Index';
import classNames from 'classnames';
import { headerContainer } from './store';
import type { ITheme } from './store';
import styles from './index.module.less';
import useWinTop from '@/utils/hooks/useWinTop';
import Image from 'next/image';
// import markAd01 from '/public/images/home/pic_market_ad@2x.png';
// import markAd02 from '/public/images/home/banner-top-Additional+material2.png';

// import closeIcon from '/public/images/market/close@svg.svg';

import { Carousel } from 'antd';
import { userModelContainer } from '@/store/userModel';
import { track } from '@/utils/track';

export interface HeaderProps {
  pageName?: string;
  // Icon: any;
  className?: string;
  style?: CSSProperties;
  theme?: ITheme;
  popoverClass?: string;
  // header模式
  mode?: 'fixed' | 'auto';
  LayoutStyle?: CSSProperties;
  loginRedirect?: boolean;
}

const HeaderContainer = (props: HeaderProps) => {
  const { popoverClass, loginRedirect } = props;
  const theme = props.theme || 'light';
  return (
    <headerContainer.Provider initialState={{ popoverClass, theme, loginRedirect }}>
      <Header theme={theme} {...props} />
    </headerContainer.Provider>
  );
};

//
const boundary = 200;

const Header = (props: HeaderProps) => {
  const { className, style, theme: originTheme, mode = 'fixed', LayoutStyle = {} } = props;
  const { theme, changeTheme, popupVisible } = headerContainer.useContainer();
  const { userInfo, markList, onClose } = userModelContainer.useContainer();
  const [isHover, setHover] = useState(false);

  const router = useRouter();
  const ref = useRef<any>();
  const top = useWinTop(boundary);
  /**
   * @description 当原始模式是light时,则需要监听滚动位置更改模式
   */
  useEffect(() => {
    if (originTheme === 'dark' && top) {
      const theme: ITheme = top > boundary ? 'light' : 'dark';
      changeTheme(theme);
    }
  }, [top, originTheme]);

  const { run: changeMouseHandle } = useDebounceFn(
    (isEnter) => {
      setHover(isEnter);
    },
    { wait: 150 },
  );
  return (
    <div
      ref={ref}
      onMouseLeave={() => {
        changeMouseHandle(false);
      }}
      onMouseMove={() => setHover(true)}
      onMouseEnter={() => {
        changeMouseHandle(true);
      }}
      className={classNames('global-header', styles.layout, styles[`layout-theme-${theme}`], {
        [styles['popup-open']]: popupVisible || isHover,
      })}
      style={{
        position: mode === 'fixed' ? 'fixed' : 'relative',
        height: 'auto',
        ...LayoutStyle,
      }}
    >
      {markList && (
        <div className={styles.bannerBox}>
          {router.asPath === '/market/list' ? (
            <Carousel dotPosition={'left'} autoplaySpeed={5000} speed={1000} autoplay>
              <div>
                <div
                  style={{ backgroundColor: '#EC584A', display: 'flex', justifyContent: 'center' }}
                >
                  <img src={'/images/advertisement/pic_market_ad3@2x.png'} height={60} alt="" />
                </div>
              </div>
              <div>
                <div
                  style={{ backgroundColor: '#EC584A', display: 'flex', justifyContent: 'center' }}
                >
                  <img src={'/images/home/pic_market_ad@2x.png'} height={60} alt="" />
                </div>
              </div>

              <div>
                <div
                  style={{ backgroundColor: '#1a66ff', display: 'flex', justifyContent: 'center' }}
                >
                  <img src={'/images/home/pic_market_ad02@2x.jpg'} height={60} alt="" />
                </div>
              </div>
            </Carousel>
          ) : (
            <div
              style={{
                backgroundColor: '#EC584A',
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (router.asPath === '/') {
                  track({
                    name: '广告点击',
                    keyword: '官网首页——横幅广告——点击',
                    userId: userInfo ? userInfo.openId : '',
                  });
                } else if (router.asPath === '/product/textin_text') {
                  track({
                    name: '广告点击',
                    keyword: '通用文字识别——横幅广告——点击',
                    userId: userInfo ? userInfo.openId : '',
                  });
                } else if (router.asPath === '/product/textin_card') {
                  track({
                    name: '广告点击',
                    keyword: '卡证文字识别——横幅广告——点击',
                    userId: userInfo ? userInfo.openId : '',
                  });
                } else if (router.asPath === '/product/textin_bill') {
                  track({
                    name: '广告点击',
                    keyword: '票据文字识别/验真———横幅广告——点击',
                    userId: userInfo ? userInfo.openId : '',
                  });
                } else if (router.asPath === '/product/textin_image') {
                  track({
                    name: '广告点击',
                    keyword: '图像智能处理——横幅广告——点击',
                    userId: userInfo ? userInfo.openId : '',
                  });
                } else if (router.asPath === '/product/textin_conversion') {
                  track({
                    name: '广告点击',
                    keyword: '文档格式转换——横幅广告——点击',
                    userId: userInfo ? userInfo.openId : '',
                  });
                }
                window.open('/market/list', '_blank');
              }}
            >
              <img src={'/images/advertisement/pic_market_ad3@2x.png'} height={60} alt="" />
            </div>
          )}
          <span className={styles.adBtn} onClick={onClose}>
            <Image src={'/images/market/close@svg.svg'} alt="" height={20} width={20} />
          </span>
        </div>
      )}
      <div className={classnames(styles.container, className)} style={{ ...style }}>
        <div className={styles.left}>
          <div className={styles.logo} onClick={() => router.push('/')} />
        </div>
        <div className={styles.navigation}>
          <NaveBar />
        </div>
        <div className={styles.right}>
          <RightContent />
        </div>
      </div>
    </div>
  );
};
export default HeaderContainer;
