import { generateUUID } from '@/utils';
import { message } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import {
  getAdRobots,
  getCatalogQuestion,
  getDocFile,
  postThumbsCancleUp,
  postThumbsDown,
  postThumbsUp,
  queryDetail,
} from '../service';
import useRouterMount from '@/utils/hooks/useMount';

export const firstHrefPlaceHolder = '/doc/guide/first';

export const getFistHref = (res) => {
  let url = '';
  if (res.data.length) {
    if (res.data[0] && res.data[0].children?.length && res.data[0].children[0].children?.length) {
      url = `/doc${res.data[0].children[0].children[0]?.path}?status=first`;
    } else if (res.data[0] && res.data[0].children?.length) {
      url = `/doc${res.data[0].children[0]?.path}?status=first`;
    } else {
      url = `/doc${res.data[0]?.path}?status=first`;
    }
  }
  return encodeURI(url);
};

function useStore(props: any) {
  const router: any = useRouter();
  const [treeData, setTreeData] = useState(props?.treeData);
  const [searchValue, setSearchValue] = useState('');
  const [clearStatus, setClearStatus] = useState(false);
  const [terms, setTerms] = useState<any>();
  const [detailData, setDetailData] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectText, setSelectText] = useState('');
  const [robotsList, setRobotsList] = useState<any>([]);
  const [thumbUpStatus, setThumbsUpStatus] = useState<boolean>(false);
  const [thumbDownStatus, setThumbsDownStatus] = useState<boolean>(false);
  const [titleModal, setTitleModal] = useState('');

  useRouterMount(() => {
    if (!treeData?.length) {
      getCatalogQuestion().then((res) => {
        if (res.code !== 200) return;
        setTreeData(res.data);
        if (router.asPath === firstHrefPlaceHolder) {
          router.replace(getFistHref(res) || '/document/list');
        }
      });
    }
  });

  useEffect(() => {
    getRobotsList();
    if (router.query.path) {
      getQueryDetail();
      getDocFileFn();
    }
  }, [router]);

  const getDocFileFn = () => {
    const path = '/' + router.query.path.join('/');
    getDocFile(path).then((res) => {
      if (res.code === 200) {
        setTerms(res.data);
      } else {
        message.error(res.msg);
      }
    });
  };
  const getQueryDetail = () => {
    const path = '/' + router.query.path.join('/');
    const uuid = localStorage.getItem('ThumbsUuid') ? localStorage.getItem('ThumbsUuid') : '';
    queryDetail(path, uuid).then((res) => {
      if (res.code !== 200) return;
      setDetailData(res.data);
    });
  };
  const getRobotsList = () => {
    getAdRobots().then((res) => {
      if (res.code !== 200) return;
      setRobotsList(res.data);
    });
  };
  const handleTitleClick = (path: number) => {
    router.push({
      pathname: `/doc/${path}`,
    });
  };
  const handleOpenClick = (path: number) => {
    window.opne(`/doc/${path}`, '_blank');
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const thumbsUp = (status: string) => {
    let service;
    const routerPath: any = router?.query?.path;
    if (status === 'up') {
      service = postThumbsUp;
    } else {
      service = postThumbsDown;
    }
    const data: any = {
      path: `/${routerPath.join('/')}`,
      uuid: localStorage.getItem('ThumbsUuid')
        ? localStorage.getItem('ThumbsUuid')
        : generateUUID(),
    };
    service(data).then((res) => {
      if (res.code === 200) {
        localStorage.setItem('ThumbsUuid', data.uuid);
        if (status === 'up') {
          setThumbsUpStatus(true);
        } else {
          setThumbsDownStatus(false);
        }
      }
    });
  };
  const thumbsCancelUp = () => {
    const routerPath: any = router?.query?.path;
    const data: any = {
      path: `/${routerPath.join('/')}`,
      uuid: localStorage.getItem('ThumbsUuid')
        ? localStorage.getItem('ThumbsUuid')
        : generateUUID(),
    };
    postThumbsCancleUp(data).then((res) => {
      if (res.code === 200) {
        localStorage.removeItem('ThumbsUuid');
        setThumbsUpStatus(false);
      }
    });
  };
  return {
    treeData,
    searchValue,
    setSearchValue,
    clearStatus,
    setClearStatus,
    terms,
    setTerms,
    handleTitleClick,
    detailData,
    handleOpenClick,
    isModalOpen,
    setIsModalOpen,
    showModal,
    closeModal,
    selectText,
    setSelectText,
    robotsList,
    thumbsCancelUp,
    thumbsUp,
    thumbUpStatus,
    setThumbsUpStatus,
    thumbDownStatus,
    setThumbsDownStatus,
    titleModal,
    setTitleModal,
  };
}
export const HelpContainer = createContainer(useStore);
