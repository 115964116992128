import styles from './index.module.scss';
import classNames from 'classnames';
import { useDocumentWidth } from '../../hooks';
import { headerContainer } from '@/components/Header/store';
import Image from 'next/image';
import Tool from './components/Tools';
import { openChat } from '@/utils/chatSDK';
import { officialTel } from '@/utils/static';
import { firstHrefPlaceHolder } from '@/containers/DocumentFAQ/store';

const DocumentPopover = () => {
  const { popoverClassName } = headerContainer.useContainer();
  const toolsList = [
    {
      title: '文档与工具',
      cardList: [
        {
          url: '/images/contact/document.png',
          title: 'API文档',
          onClick: () => {
            window.open('/document/index', '_blank');
          },
        },
        {
          url: '/images/contact/help_center.png',
          title: '帮助中心',
          onClick: () => {
            window.open(firstHrefPlaceHolder, '_blank');
          },
        },
      ],
    },
    {
      title: '服务与支持',
      cardList: [
        {
          url: '/images/contact/online.png',
          title: '在线客服',
          onClick: openChat,
        },
        {
          url: '/images/contact/business.png',
          title: '商务留言',
          onClick: () => {
            window.open('/contact', '_blank');
          },
        },
      ],
    },
  ];
  useDocumentWidth('.popover-effect');
  return (
    <div className={classNames(styles['document-popover-wrapper'], 'popover-effect', 'navBox')}>
      <div className={classNames(styles.support_wrapper, 'navLeft')}>
        <div className={classNames(styles.support, 'leftContent')}>
          <div className="navLeftTitle">文档与支持</div>
          <div className="navLeftDesc">TextIn为您提供完善而全面客户服务支持</div>
          <div className={styles.support_item}>
            <Image
              src={'/images/contact/ic_navigation_customize@2x.png'}
              alt=""
              width={36}
              height={36}
            />
            <div className={styles.desc}>专属解决方案定制</div>
          </div>
          <div className={styles.support_item}>
            <Image
              src={'/images/contact/ic_navigation_support@2x.png'}
              alt=""
              width={36}
              height={36}
            />
            <div className={styles.desc}>技术专家服务支持</div>
          </div>
          <div className={styles.support_item}>
            <Image
              src={'/images/contact/ic_navigation_serve@2x.png'}
              alt=""
              width={36}
              height={36}
            />
            <div className={styles.desc}>一体化全流程服务</div>
          </div>
        </div>
      </div>

      <div
        className={classNames(
          styles['document-popover-content'],
          popoverClassName,
          'experience-popover',
          'navContent',
        )}
      >
        <div className={styles.tools}>
          {toolsList.map((tool, idx) => {
            return <Tool {...tool} key={idx.toString()} />;
          })}
        </div>
        <div className={styles.contactWrap}>
          <div className={styles.contact}>
            <Image src={'/icons/phone_black.svg'} width={16} height={16} alt="" />
            <span style={{ marginLeft: '8px' }}>{officialTel}</span>
          </div>
        </div>
      </div>

      <div className={classNames(styles.navRight, 'navRight')}>{/* 广告区 */}</div>
    </div>
  );
};
export default DocumentPopover;
